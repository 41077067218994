import * as types from "../core/actionTypes";
import * as zapierServices from "../services/zapierServices";
import { catchError } from "../core/error.handler";
import toast from "../components/libraries/toast";
export function rotateZapierKey() {
  return function (dispatch) {
    return zapierServices.rotateZapierKey().then(function (result) {
      dispatch({
        type: types.ROTATE_ZAPIER_KEY_SUCCESS,
        payments: result.data
      });
      toast.success('API Key Rotated');
      return result;
    })["catch"](function (error) {
      catchError(error, dispatch, types.ROTATE_ZAPIER_KEY_FAILURE);
      toast.error('Failed to Rotate API Key');
    });
  };
}